import React from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import "./gallery.css";
// import axios from "../network/api.js";
import { baseUrl, baseMediaUrl } from "../network/constants/Constants";

function Gallery({ props }) {
  // const [loading, setLoading] = useState(false);
  // const [gallery, setGallery] = useState([]);

  // useEffect(() => {
  //   fetchGallery();
  // }, []);

  // const fetchGallery = (props) => {
  //   setLoading(true);

  //   axios
  //     .get(baseUrl + "webapp/gallery/")
  //     .then((response) => {
  //       console.log(response.data);
  //       setLoading(false);

  //       setGallery(response.data);
  //       console.log(response.data);
  //     })
  //     .catch((error) => {
  //       // console.log(error + "Arjun");

  //       setLoading(false);
  //     });
  // };

  const navigate = useNavigate();

  function HandleNavigation() {
    navigate("./gallerypage");
  }

  

  return (
    <section id="gallery">
      <div>
        <Container>
          <div className="gallery-head">Image Gallery</div>
          <div className="image">
            <Row>
              {props &&
                props

                  .sort((a, b) => (a.id > b.id ? -1 : 1))
                  .map((galleryObj, index) => {
                    return (
                      <Col lg={4} className="gallery-col">
                        <div
                          className="img-card"
                        >
                          <img
                            className="gallery-img img-fluid"
                            src={baseMediaUrl + galleryObj.gallerybanner}
                            alt="Gallery"
                          />
                        </div>
                      </Col>
                    );
                  })}
            </Row>
            
          </div>
          <Button
            className="view-more"
            type="submit"
            onClick={HandleNavigation}
          >
            View More
          </Button>
        </Container>
      </div>
    </section>
  );
}

export default Gallery;
