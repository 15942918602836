import React from "react";
import Container from "react-bootstrap/esm/Container";
import "./copyright.css";

function CopyRight() {
  return (
    <section id="copyright">
      <div>
        <Container>
          <div className="copyrights">
            Copyright 2023 - 2024 All Rights Reserved
            <br />
            Website Designed & Developed By{" "}
            <a href="https://nthindex.com">Nthindex Software Solutions LLP</a>
          </div>
        </Container>
      </div>
    </section>
  );
}

export default CopyRight;
