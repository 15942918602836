import React from "react";
import Container from "react-bootstrap/esm/Container";
import "./aboutus.css";

function AboutUs() {
  return (
    <section id="about">
        <div className="about">
      <Container>
      <div className="ab">
      <div className="about-us">About Us</div>
        <div className="border-left"></div>
          <div className="border-right"></div>
        <div className="desc-about">
          <p>
            Established in 2012, Professors Academy aims at providing the best
            education for students of all sections of society. Within a few
            years, along the academic excellence our institute achieved its
            fame. Our institute incorporated to impart quality education to
            students preparing for various exams with sky-high ideas and
            commitment to excellence.
          </p>
          <br />
          <p>
            We are really proud that since the beginning itself, institute have
            enhanced outcome-oriented service for students irrespective of their
            economic standards. Financial incapability is a major hindrance for
            students to get access in reputed coaching institutes. The highly
            competitive exams need proper guidance from the beginning itself.
            Those skilled and talented students often left their dreams of
            medical/engineering fields because of economic backwardness. We the
            professor's academy is highly promoting passioned students
            especially of economically backward sections. We are grateful that
            our team work resulted in dream accomplishment of thousands of
            students with in these few years. It is to be acknowledged that the
            efficient teamwork of teaching and non-teaching staffs resulted in
            high success for academy along with the students. Efficient teaching
            methods and exam-oriented study materials enhanced the success of
            our institute.
          </p>
          <br />
          <div className="desc-heading">ACHIEVEMENTS</div>
          
            • Enhanced thousands of students on realising their dreams and also
            guiding the students to get enrolled among the top most academic/
            research institutes. <br/>
          <p>  • Successively completed the question bank for Medical-Engineering
            entrance exams for one of the leading newspapers of India,
            MalayalaManorama.
          </p>
          <br />
          <div className="desc-heading">VISION </div>
          <p>
            We are on a journey of innovating educational methods from the
            traditional ways of teaching been used in these decades. to Simplify
            the studying process without compromising the results is our vision
          </p>
        </div>
      </div>
      
      </Container>
    </div>
    </section>
  
  );
}

export default AboutUs;
