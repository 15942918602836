import React from 'react'
import Container from "react-bootstrap/esm/Container";
import Button from "react-bootstrap/Button";
// import React, { useState } from 'react';
import "./entrancecoaching.css"
import { useState } from 'react';

const Entrancecoaching = () => {
  
  const [coaching,setCoaching] = useState([]);

  const handleButtonClick = () => {
    const section = document.getElementById('course');
    section.scrollIntoView({ behavior: 'smooth'})
  }

  const handleButtonClick1 = () => {
    const section1 = document.getElementById('contact');
    section1.scrollIntoView({ behavior: 'smooth'})
  }

  

  return (
    <section id="entrancecoaching">
    <div className="entrancecoaching">
  <Container>
  <div className="ab">
    <h1 className="coaching-heading">
    Professors Academy: Top Entrance Coaching Center in Thrissur
    </h1>
    
    <div className="desc-about">
      <p className='para'>
      Looking for the best entrance coaching center in Thrissur? Look no further than Professors Academy, Situated in Kerala and providing the best entrance coaching. Professors Academy has established itself as the leading institution dedicated to helping students achieve their dreams of success in entrance exams. Our exceptional track record and a team of highly experienced professors have earned us the reputation of being the best entrance coaching center in Thrissur. With an established history of achieving remarkable results, we are fully dedicated to offering inclusive coaching programs that empower students with the essential skills and knowledge required to excel in their entrance tests. At Professors Academy, we take pride in our unwavering commitment to student success.

At Professors Academy, we firmly believe that success stems from a combination of knowledge, guidance, and determination. By choosing our academy, students embark on a transformative educational journey, characterized by comprehensive coaching, experienced mentors, and a commitment to their overall growth.

      </p>
     
      <br />
      <h2 className="entrance-heading">Expert Faculty: Guiding You Towards Entrance Coaching Excellence </h2>
    <br />
      <p> At Professors Academy, we understand that the key to exceptional education lies in our faculty members. Our team of expert professors, known for their extensive subject knowledge and teaching expertise, are dedicated to guiding students towards entrance coaching excellence. As the premier entrance coaching center in Thrissur, we take pride in our faculty's commitment to ensuring that each student receives personalized attention and guidance. With their comprehensive understanding of entrance coaching patterns and effective teaching methodologies, our faculty equips students with the necessary skills and strategies to excel in their exams. Achieving excellence in entrance coaching requires the guidance of experienced mentors who understand the intricacies of these highly competitive tests. At Professors Academy, we take great pride in our team of expert faculty members who are dedicated to guiding you toward entrance coaching excellence.

Our faculty members, specializing in entrance coaching, bring together a combination of expertise and experience to ensure your success. With their strong command of subjects and extensive knowledge of the latest exam patterns, question formats, and marking schemes, they provide valuable insights and strategies to help you effectively tackle entrance exams. As industry-leading mentors in entrance coaching, our faculty members possess the necessary skills and proficiency to guide and mentor you throughout your preparation. We prioritize your success and aim to equip you with the essential tools and resources required for entrance exam success.

At Professors Academy, we take pride in being the best entrance coaching center, where our highly skilled faculty members go above and beyond the curriculum. With our expertise in entrance coaching, we focus on not just imparting subject knowledge but also nurturing your problem-solving skills, critical thinking abilities, and time management techniques. Through interactive sessions, engaging discussions, and rigorous practice exercises, our faculty members ensure that you develop the necessary skills to analyze complex problems and apply concepts effectively. Our goal is to optimize your performance in the entrance exams by providing comprehensive guidance and personalized attention. As the leading entrance coaching center, we are committed to equipping you with the tools and strategies needed to excel in your exams.

      </p>
      <Button className='course' type='submit' onClick={handleButtonClick}> 
        Course
      </Button>
      <br />

      <h2 className="entrance-heading">Entrance Coaching Center in Thrissur with Comprehensive Curriculum</h2>
    <br />
      <p> Professors Academy believes in a comprehensive approach to entrance coaching preparation. Our carefully crafted curriculum covers all essential aspects of the exams, providing students with the tools they need to succeed. Our study materials, practice tests, and mock exams are designed to simulate the real exam environment, helping students familiarize themselves with the exam pattern and gain confidence. Through intensive classroom sessions, interactive doubt-solving sessions, and regular assessments, we ensure that our students are thoroughly prepared to overcome the challenges of entrance exam preparations. As the premier entrance exam coaching institutes, we empower students to unlock their full potential and achieve success. 
Join Professors Academy, the leading entrance coaching center in Thrissur, and embark on a transformative journey towards entrance coaching success. Our exceptional faculty, comprehensive curriculum, and unwavering dedication will guide you toward realizing your aspirations of gaining admission to your desired educational institutions. Professors Academy also has branches in Irinjalakuda, Chalakudy, and Thrissur.  Join our entrance coaching and Experience the difference with the top entrance coaching center in Thrissur, join Professors Academy now!


      </p>
      <Button className='course' type='submit' onClick={handleButtonClick1}>
        Contact
      </Button>
    </div>
  </div>
  
  </Container>
</div>
</section>
  )
}

export default Entrancecoaching;
