import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NotificationDetails from "./notificationdetails/notificationdetails";
import NotificationNavBar from "./notificationdetails/notificationNavbar.jsx";
import Marque from "./marquee/marquee.jsx";
import GalleryPage from "./galleryviewpage/galleryviewpage.jsx";
import VideoView from "./videoviewpage/videoview";
import Testimonials from "./testimonial/testimonial";
import Homepage from "./homepage/homepage";
// import HeaderHome from "./fnav/fnav";
// import Bar from "./nav/nav";
// import { useState } from "react";
// import Dummy from "./dummy/dummy";


function App() {  
  // const [galleryData,setGalleryData]=useState([]);


  return (
    
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/notificationdetails" element={<NotificationDetails />}/>
          <Route path="/marquee" element={<Marque />} />
          <Route path="/gallerypage" element={<GalleryPage />} />
          <Route path="/videosection" element={<VideoView />} />
          <Route path="/testimonial" element={<Testimonials />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
