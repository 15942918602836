import React from "react";
import Container from "react-bootstrap/esm/Container";
import logo from "../assets/logo-1.png";
import footerline from "../assets/footer/footerline.png";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import "./footer.css";

function Footer() {
  return (
    <section id="footer">
      <div className="footer">
        <Container>
          <a href="/#home">
            <img className="img-fluid logo" src={logo} alt="brand-name" />
          </a>
          <div className="google-map">
            <Row>
              <Col lg={6}>
                <div className="map-location">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3924.9387936457615!2d76.20994807368982!3d10.34677926697442!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba7f6461a3c10b3%3A0x1ff67ce5aafadf70!2sPROFESSORS%20ACADEMY!5e0!3m2!1sen!2sin!4v1684735270296!5m2!1sen!2sin"
                    width="404"
                    height="206"
                    style={{ border: "11px solid #06376D" }}
                  ></iframe>
                </div>
                <div className="address">
                  IRINJALAKUDA
                  <br />
                  Blaze Court, Main Road Irinjalakuda - 680222
                </div>
              </Col>
              <Col lg={6}>
                <div className="map-location">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3922.6808195280587!2d76.20928361479857!3d10.525786092491916!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTDCsDMxJzMyLjgiTiA3NsKwMTInNDEuMyJF!5e0!3m2!1sen!2sin!4v1684736622090!5m2!1sen!2sin"
                    width="404"
                    height="206"
                    style={{ border: "11px solid #06376D" }}
                  ></iframe>
                </div>
                <div className="address">
                  THRISSUR
                  <br />
                  City Centre, Naikkanal, Thrissur, Kerala 680001
                </div>
              </Col>
            </Row>
          </div>

          <div className="email-address">
            <a
              class="dim-white"
              href="mailto:professorsacademyijk@gmail.com"
            >
              professorsacademyijk@gmail.com
            </a>
            <br />
            <a href="tel:9037187800" class="float1" target="_blank">
              9037187800
            </a>
            {/* <a href="tel:9745456460" class="float1" target="_blank">
              9745456460
            </a> */}
          </div>
          <img
            className="img-fluid footerline"
            src={footerline}
            alt="Footerline"
          />

          <Row className="centere-row">
            <Col lg={2}>
              {/* <div className="centre">Blaze Court</div> */}
              <div className="locations">Irinjalakuda</div>
            </Col>
            <Col lg={2}>
              {/* <div className="centre">City Centre</div> */}
              <div className="locations">Thrissur</div>
            </Col>
            {/* <Col lg={2}>
              <div className="centre">St Joseph's Girls</div>
              <div className="locations">Thrissur</div>
            </Col> */}
            <Col lg={2}>
              {/* <div className="centre">Saraswathy vidyanikethan</div> */}
              <div className="locations">Kodakara</div>
            </Col>
            <Col lg={2}>
              {/* <div className="centre">SN Vidyabavan</div> */}
              <div className="locations">Chentrappinni</div>
            </Col>
            <Col lg={2}>
              {/* <div className="centre">St Anne's school</div> */}
              <div className="locations">Anathadam</div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}

export default Footer;
