import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import "./homepage.css";
import "../App.css";
import Header from "../header/header";
import "../header/header.css";
import Navigation from "../navbar/navbar";
import Marque from "../marquee/marquee";
import BannerImg from "../banner/banner";
import Entrancecoaching from "../entrancecoachingdetails/entrancecoaching";
import CourseDetails from "../course/courses";
import WelcomeMessage from "../welcome/welcome_message";
import DepartmentHeads from "../departments/department";
import Branches from "../branches/branch";
import Gallery from "../gallery/gallery";
import "../galleryviewpage/galleryviewpage.css";
import Contact from "../contact/contact";
import Footer from "../footer/footer";
import CopyRight from "../copyright/copyright";
// import "../dummy/dummy.css";
// import HeaderHome from "../fnav/fnav";
import axios from "../network/api.js";
import { baseUrl, config } from "../network/constants/Constants";
import Videoplayer from "../videosection/video";
import Testimonials from "../testimonial/testimonial";
import AboutUs from "../about/aboutus";

function Homepage() {
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState([]);
  const [course, setCourse] = useState([]);
  const [galleryData, setGalleryData] = useState([]);
  const [bannerData, setBannerData] = useState([]);
  const [coaching,setCoaching] = useState([]);

  let data = [];

  useEffect(() => {
    fetchHome();
  }, []);

  const fetchHome = () => {
    setLoading(true);
    axios
      .get(baseUrl + "home/home/")
      .then((response) => {
        setLoading(false);
        data = response.data;
        setBannerData(response.data.banner);
        setGalleryData(response.data.gallery);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error: " + error);
      });
  };

  return (
    <section id="home">
      <div>
        <Header />
        <Navigation />
        {/* <HeaderHome/> */}
        <Marque/>
        <BannerImg props={bannerData} />
        <Entrancecoaching />
        <CourseDetails />
        <WelcomeMessage />
        <DepartmentHeads />
        <AboutUs />
        <Branches />
        <Gallery props={galleryData} />
        <Videoplayer />
        <Testimonials />
        <Contact />
        <Footer />
        <CopyRight />
      </div>
    </section>
  );
}

export default Homepage;
