import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import Button from "react-bootstrap/Button";
import contact from "../assets/contact/contact-us-art.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./contact.css";
// import Alert from "@mui/material/Alert";
import axios from "../network/api";
import { baseUrl, config } from "../network/constants/Constants";

function Contact() {
  const [loading, setLoading] = useState(false);
  const [contactName, setContactName] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactMail, setContactMail] = useState("");
  const [contactMessage, setContactMessage] = useState("");
  const [contactError, setContactError] = useState("");
  const [visibility, setVisibility] = useState(false);

  function handleSubmitContact(e) {
    var filter =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (contactName.length === 0) {
      setContactError("Please enter your name!");
    } else if (contactPhone.length < 10 && contact.length > 16) {
      setContactError("Please enter your phone number!");
    } else if (!filter.test(contactMail)) {
      setContactError("Please enter a valid email id!");
    } else if (contactMessage.length === 0) {
      setContactError("Please enter a message!");
    } else {
      submitMail();
    }
  }

  const submitMail = () => {
    setLoading(true);
    const payload = {
      name: contactName,
      phone: contactPhone,
      email: contactMail,
      message: contactMessage,
    };

    console.log("payload: " + JSON.stringify(payload));
    axios
      .post(baseUrl + "webapp/contact/upload/", payload)
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        // setVisibility(!visibility);
        // setContactError("");
        // setContactMail("");
        // setContactMessage("");
        // setContactName("");
        // setContactPhone("");
      })
      .catch((error) => {
        console.log("error: " + error);
        setLoading(true);
      });
  };

  return (
    <section id="contact">
      <div className="contact">
        <Container>
          <div className="contact-head">Contact Us</div>
          <div className="border-left"></div>
          <div className="border-right"></div>
          <div className="forms">
            <Row>
              <Col lg={5}>
                <form>
                  <div className="form-head">Get in Touch</div>

                  <div>
                    <input
                      id="name"
                      class="form-id"
                      type="text"
                      name="name"
                      placeholder="Name"
                      required=""
                      onChange={(e) => setContactName(e.target.value)}
                    />
                  </div>

                  <div>
                    <input
                      id="number"
                      class="form-id mt-3"
                      name="number"
                      placeholder="Phone"
                      required=""
                      onChange={(e) => setContactPhone(e.target.value)}
                    />
                  </div>
                  <div>
                    <input
                      id="email"
                      class="form-id mt-3"
                      type="email"
                      name="email"
                      placeholder="email"
                      onChange={(e) => setContactMail(e.target.value)}
                    />
                  </div>
                  <div>
                    <textarea
                      id="message"
                      class="form-id mt-3"
                      type="text"
                      name="subject"
                      rows="4"
                      placeholder="Message"
                      required=""
                      onChange={(e) => setContactMessage(e.target.value)}
                    />
                  </div>
                  {/* <div className="contacterror">
                      {contactError && (
                        <Alert
                          variant="standard"
                          size="small"
                          severity="error"
                          className="contactalert"
                        >
                          {contactError}
                        </Alert>
                        )}

                        </div>  */}

                  {contactError && contactError((contact, index) => {})}

                  <div className="submit">
                    <Button
                      className="form-button m-0"
                      type="submit"
                      onClick={handleSubmitContact}
                    >
                      Submit
                    </Button>
                  </div>
                </form>
              </Col>

              <Col className="contact-img" lg={7}>
                <img
                  className="contact-us img-fluid"
                  src={contact}
                  alt="Contact"
                />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </section>
  );
}

export default Contact;
