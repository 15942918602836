import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { HashLink } from "react-router-hash-link";
// import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../assets/logo-1.png";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
// import "./navbar.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import NotificationDetails from "../notificationdetails/notificationdetails";
import { Route, HashRouter, useLocation } from "react-router-dom";
import jQuery, { nodeName } from "jquery";
// import "./notificationNavbar.css";

const NotificationNavBar = (props) => {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    //  }, []);

    // Get all sections that have an ID defined
    const sections = document.querySelectorAll("section[id]");

    // Add an event listener listening for scroll
    window.addEventListener("scroll", navHighlighter);

    function navHighlighter() {
      // Get current scroll position
      let scrollY = window.pageYOffset;

      // Now we loop through sections to get height, top and ID values for each
      sections.forEach((current) => {
        const sectionHeight = current.offsetHeight;
        const sectionTop = current.offsetTop - 50;
        const sectionId = current.getAttribute("id");

        /*
    - If our current scroll position enters the space where current section on screen is, add .active class to corresponding navigation link, else remove it
    - To know which link needs an active class, we use sectionId variable we are getting while looping through sections as an selector
    */
        if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
          document
            .querySelector(".navbar-nav a[href*=" + sectionId + "]")
            .classList.add("active");
        } else {
          document
            .querySelector(".navbar-nav a[href*=" + sectionId + "]")
            .classList.remove("active");
        }
      });
    }

    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        // if (element) {
          element.scrollIntoView();
          console.log(element);
        // }
      }, 0);
    }

    const navi = document.getElementById("navigation");
    const topOfNav = navi.offsetTop;
    const element = document.getElementById("stickyClass");

    return () => {
      function fixNav() {
        if (window.scrollY > topOfNav) {
          element.classList.add("nav-sticky");
        } else {
          element.classList.remove("nav-sticky");
        }
      }
      window.addEventListener("scroll", fixNav);
    };
  }, [pathname, hash, key]);

  const navigate = useNavigate();

  function NotificationDetails() {
    navigate("./notificationdetails");
  }

  return (
    <section id="navigation">
      {/* <HashRouter>
        <Route exact path="/" component={NotificationDetails} />        
      </HashRouter> */}
      <Navbar bg="light" expand="lg" className="navbar" id="stickyClass">
        <Container className="navigation">
          <a href="/#home">
          <img className="home-img" src={logo} alt="brand-name" />
          </a>
          {/* <div className="bell-container bell1 d-mg-none d-sm-inline d-lg-none ms-auto">
            <div className="icon">
              <FontAwesomeIcon icon={faBell}></FontAwesomeIcon>
            </div> */}
            {/* <div className="notification"></div> */}
          {/* </div> */}
          <Navbar.Toggle aria-controls="basic-navbar-nav"></Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <div className="ms-auto">
              <Nav className="nav-details ">
                <Nav.Link href="/#home" className="pe-lg-4">
                  Home
                </Nav.Link>
                {/* <HashLink to={"/#course"}>back</HashLink> */}
                <Nav.Link href="/#course" className="navLink pe-lg-4">
                  Courses
                </Nav.Link>
                <Nav.Link href="/#about" className="navLink pe-lg-4">
                  About Us
                </Nav.Link>
                <Nav.Link href="/#gallery" className="navLink pe-lg-4">
                  Gallery
                </Nav.Link>
                <Nav.Link href="/#contact" className="navLink pe-lg-4">
                  Contact Us
                </Nav.Link>

                {/* <div className="bell-container d-none d-mg-none d-sm-none d-lg-inline">
                  <div className="icon">
                    <FontAwesomeIcon
                      icon={faBell}
                      onClick={NotificationDetails}
                    ></FontAwesomeIcon>
                  </div>
                  <div className="notification"></div>
                </div> */}
                <Button className="navbar-btn" href="#">
                  Signin
                </Button>
              </Nav>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </section>
  );
};
jQuery(function ($) {
  // Code for changing active link on clicking
  var btns = $("navbar-nav > nav-link");
  if (btns.length != 0) {
    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", function () {
        var current = document.getElementsByClassName("active");

        current[0].className = current[0].className.replace(" active", "");
        this.className += " active";
      });
    }
  }

  //   $(document).ready(function () {
  //     $('.navbar-nav navLink a').click(function(e) {

  //         $('.navbar-nav navLink.active').removeClass('active');

  //         var $parent = $(this).parent();
  //         $parent.addClass('active');
  //         e.preventDefault();
  //     });
  // });
});

NotificationNavBar.propTypes = {};
export default NotificationNavBar;
