import React, { Component, useEffect, useState } from "react";
import Img from "../assets/testimonials/Ellipse 7.png";
import qt from "../assets/welcome_message/quote1.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./testimonial.css";
import axios from "../network/api.js";
import { baseUrl, baseMediaUrl } from "../network/constants/Constants";

function Testimonials() {
  const [loading, setLoading] = useState(false);
  const [testimonial, setTestimonial] = useState([]);

  useEffect(() => {
    fetchTestimonial();
  }, []);

  const fetchTestimonial = () => {
    setLoading(true);

    axios
      .get(baseUrl + "webapp/testimonial/")
      .then((response) => {
        console.log(response.data);
        setLoading(false);

        setTestimonial(response.data);
        console.log("testimonials" + response.data);
      })
      .catch((error) => {
        // console.log(error + "Arjun");

        setLoading(false);
      });
  };
  return (
    <section id="testimonials">
      <div className="test-head">Testimonials</div>

      <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={6100}
      >
        {testimonial &&
          testimonial
          .sort((a, b) => (a.id > b.id ? -1 : 1))
          .map((testimonials, index) => {
            return (
              <div className="test-img">
                <img src={testimonials.image} className="staff" alt="staff" />
                <div className="myCarousel">
                  <h3>{testimonials.author}</h3>
                  <h4>{testimonials.designation}</h4>
                  <img src={qt} className="qt" alt="quote" />
                  <p>{testimonials.message}</p>
                </div>
              </div>
            );
          })}
        {/* <div className="test-img">
          <img src={Img} className="staff" alt="staff" />
          <div className="myCarousel">
            <h3>Shirley Fultz</h3>
            <h4>Designer</h4>
            <img src={qt} className="qt" alt="quote" />
            <p>
              It's freeing to be able to catch up on customized news and not be
              distracted by a social media element on the same site It's freeing
              to be able to catch up on customized news and not be distracted by
              a social media element on the same site It's freeing to be able to
              catch up on customized news and not be distracted by a social
              media element on the same site
            </p>
          </div>
        </div>
        <div className="test-img">
          <img src={Img} className="staff" alt="staff" />
          <div className="myCarousel">
            <h3>Shirley Fultz</h3>
            <h4>Designer</h4>
            <img src={qt} className="qt" alt="quote" />
            <p>
              It's freeing to be able to catch up on customized news and not be
              distracted by a social media element on the same site It's freeing
              to be able to catch up on customized news and not be distracted by
              a social media element on the same site It's freeing to be able to
              catch up on customized news and not be distracted by a social
              media element on the same site
            </p>
          </div>
        </div> */}
      </Carousel>
    </section>
  );
}

export default Testimonials;
