import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import "./video.css";
import Container from "react-bootstrap/esm/Container";
import ReactPlayer from "react-player/youtube";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import axios from "../network/api.js";
import { baseUrl, baseMediaUrl } from "../network/constants/Constants";

function Videoplayer() {
  const [loading, setLoading] = useState(false);
  const [video, setVideo] = useState([]);

  useEffect(() => {
    fetchVideo();
  }, []);

  const fetchVideo = () => {
    setLoading(true);

    axios
      .get(baseUrl + "webapp/video/")
      .then((response) => {
        console.log(response.data);
        setLoading(false);

        setVideo(response.data);
        console.log("video" + response.data);
      })
      .catch((error) => {
        // console.log(error + "Arjun");

        setLoading(false);
      });
  };

  const navigate = useNavigate();

  function HandleNavigation() {
    navigate("./videosection");
  }

  return (
    <section id="videoplayer">
      <Container>
        <div className="video-heading">Video Gallery</div>
        <Row>
          {video &&
            video
              .sort((a, b) => (a.id > b.id ? -1 : 1))
              .map((videos, index) => {
                if (index < 6) {
                  return (
                    <Col lg={6}>
                      <div className="video">
                        <ReactPlayer
                          url={videos.youtube_link}
                          className="reactplayer"
                        />
                      </div>
                    </Col>
                  );
                }
              })}
          {/* <Col lg={6}>
            <div className="video">
              <ReactPlayer
                url="https://youtu.be/AJdRGKRkODs"
                className="reactplayer"
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="video">
              <ReactPlayer
                url="https://youtu.be/AJdRGKRkODs"
                className="reactplayer"
              />
            </div>
          </Col> */}
        </Row>

        <Button className="view-more" type="submit" onClick={HandleNavigation}>
          View More
        </Button>
      </Container>
    </section>
  );
}

export default Videoplayer;
