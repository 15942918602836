import React from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Officer from "../assets/welcome_message/officer6.jpeg";
import Quote1 from "../assets/welcome_message/quote1.svg";
import Quote from "../assets/welcome_message/quote.svg";
import "./welcome_message.css";

function WelcomeMessage() {
  return (
    <section id="welcome">
      <div className="welcome-page">
        <Container>
          <div className="message-heading">Welcome Message</div>
          <div className="msg">
            <div className="border-left"></div>
            <div className="border-right"></div>
            <div className="msg-content">
              <img className="officer" src={Officer} alt="Executive" />
              <div className="officer-name">Prof. Inasu</div>
              <div className="designation">Academic Director</div>
              <div className="content">
                <Row>
                  <Col lg={1} className="quote1">
                    <img src={Quote1} alt="double quotes mt-3" />
                  </Col>
                  <Col lg={10} className="">
                    <div className="welcome-note">
                      Education enables a person to face new challenges, make
                      progress and lead a successful life. So, I want to
                      encourage all students to get proper education through
                      proper educational institution. I congratulate the parents
                      and students for making the right choice of choosing
                      Professors Academy. Education at Professors Academy is not
                      just about academic excellence or steady income, but an
                      environment where culture and human skills converge. The
                      institute has undergone many reforms to transform itself
                      into what it is today. We now welcome students to come
                      forward to experience the difference. Adhering to this
                      contemporary world of cut-throat competition, we at
                      Professors Academy team aim to create an interdisciplinary
                      and holistic academic environment that enables students to
                      acquire knowledge without pressure. I strongly believe in
                      the ability of my dedicated staff members to build the
                      organization into one of the leading organizations in the
                      region. I am happy to welcome all students to the world of
                      competitive exam coaching and wish them all the best to
                      unlock and realize their potential.
                    </div>
                  </Col>
                  <Col lg={1} className="quote2">
                    <img src={Quote} alt="double quotes" />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </section>
  );
}

export default WelcomeMessage;
