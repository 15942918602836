import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import "./videoview.css";
import Container from "react-bootstrap/esm/Container";
import ReactPlayer from "react-player/youtube";
import GalleryNavBar from "../videoviewpage/videonavbar";
import Header from "../header/header";
import axios from "../network/api.js";
import { baseUrl, baseMediaUrl } from "../network/constants/Constants";

function VideoView() {
  const [loading, setLoading] = useState(false);
  const [video, setVideo] = useState([]);

  useEffect(() => {
    fetchVideo();
  }, []);

  const fetchVideo = () => {
    setLoading(true);

    axios
      .get(baseUrl + "webapp/video/")
      .then((response) => {
        console.log(response.data);
        setLoading(false);

        setVideo(response.data);
        console.log("video" + response.data);
      })
      .catch((error) => {
        // console.log(error + "Arjun");

        setLoading(false);
      });
  };
  return (
    <section id="videoplayer">
      <Header />
      <GalleryNavBar />
      <Container>
        <div className="video-heading">Video Gallery</div>
        <Row>
          {video &&
            video
              .sort((a, b) => (a.id > b.id ? -1 : 1))
              .map((videos, index) => {
                return (
                  <Col lg={6}>
                    <div className="video">
                      <ReactPlayer
                        url={videos.youtube_link}
                        className="reactplayer"
                      />
                    </div>
                  </Col>
                );
              })}
          {/* <Col lg={6}>
            <div className="video">
              <ReactPlayer
                url="https://youtu.be/AJdRGKRkODs"
                className="reactplayer"
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="video">
              <ReactPlayer
                url="https://youtu.be/AJdRGKRkODs"
                className="reactplayer"
              />
            </div>
          </Col> */}
        </Row>
      </Container>
    </section>
  );
}

export default VideoView;
