import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import { useNavigate } from "react-router-dom";
import "./marquee.css";
import axios from "../network/api.js";
import { baseUrl, baseMediaUrl } from "../network/constants/Constants";

function Marque() {
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState([]);

  useEffect(() => {
    fetchMarque();
  }, []);

  const fetchMarque = () => {
    setLoading(true);

    axios
      .get(baseUrl + "webapp/notification")
      .then((response) => {
        console.log(response.data);
        setLoading(false);

        setNotification(response.data);
        console.log("notification" + response.data);
      })

      .catch((error) => {
        setLoading(false);
      });
  };

  const navigate = useNavigate();

  function NotificationDetails() {
    navigate("./notificationdetails");
  }
  return (
    <section id="marquee">
      <button className="marquee" onClick={NotificationDetails}>
        {notification &&
          notification.map((obj, index) => {
            return (
              <div className="scrolling">
                <Marquee speed={20} pauseOnClick={true} gradient={false} loop={5} >
                  <div className="ms-5 ps-5"> {obj.title}</div>
                </Marquee>
              </div>
            );
          })}
      </button>
    </section>
  );
}

export default Marque;
