import Carousel from "react-bootstrap/Carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import "./banner.css";
import { useEffect, useState } from "react";
import axios from "../network/api.js";
import { baseUrl, baseMediaUrl } from "../network/constants/Constants";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import modalimg from "../assets/banner/officr1.jpeg";

function BannerImg({ props }) {
  const [loading, setLoading] = useState(false);

  const [popup, setPopup] = useState([]);

  useEffect(() => {
    fetchPopup();
  }, []);

  const fetchPopup = () => {
    setLoading(true);

    axios
      .get(baseUrl + "webapp/PopUp/")
      .then((response) => {
        console.log(response.data);
        setLoading(false);

        setPopup(response.data);
        console.log("popup" + response.data);
      })
      .catch((error) => {
        // console.log(error + "Arjun");

        setLoading(false);
      });
  };

  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <section id="banner">
      <div className="carousel">
        <Carousel variant="dark">
          {props &&
            props.map((bannerObj, index) => {
              {
                /* {
                console.log(bannerObj.banner);
              } */
              }
              return (
                <Carousel.Item interval={1000}>
                  <div className="carousel-section">
                    <img
                      className="img-fluid test"
                      src={baseMediaUrl + bannerObj.banner}
                      alt="Banners"
                    />
                  </div>
                </Carousel.Item>
              );
            })}
        </Carousel>

        <div>
          <a
            href="https://api.whatsapp.com/send?phone=9037187800 &amp;text=Hello!"
            class="float"
            target="_blank"
          >
            <FontAwesomeIcon icon={faWhatsapp} className="fa-whatsapp" />
          </a>
        </div>
      </div>
      {popup &&
        popup.map((popup, index) => {
          if (index < 1) {
          return (
            <Modal show={show} onHide={handleClose} centered={true}>
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <div>
                  <img src={popup.image} alt="modal" />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          );
          }
        })}
    </section>
  );
}

export default BannerImg;
