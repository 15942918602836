import React from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import centre1 from "../assets/branch/icon-map.svg";
import centre2 from "../assets/branch/icon-map.svg";
import centre3 from "../assets/branch/icon-map.svg";
import centre4 from "../assets/branch/icon-map.svg";
import centre5 from "../assets/branch/icon-map.svg";
import centre6 from "../assets/branch/icon-map.svg";
import "./branch.css";

function Branches() {
  return (
    <section id="branches">
      <div className="branch-details">
        <Container>
          <div className="branch-head">Our Centres</div>
          <Row>
            <Col lg={4} md={6}>
              <div className="centres">
                <img className="img-fluid" src={centre1} alt="centre1" />
                {/* <div className="centre-name">City Centre</div> */}
                <div className="centre-location">Thrissur</div>
              </div>
            </Col>
            {/* <Col lg={4} md={6}>
              <div className="centres">
                <img className="img-fluid" src={centre2} alt="centre1" />
                <div className="centre-name">St Joseph's Girls</div>
                <div className="centre-location">Thrissur</div>
              </div>
            </Col> */}

            <Col lg={4} md={6}>
              <div className="centres">
                <img className="img-fluid" src={centre3} alt="centre1" />
                {/* <div className="centre-name">Blaze Court</div> */}
                <div className="centre-location">Irinjalakkuda</div>
              </div>
            </Col>

            <Col lg={4} md={6}>
              <div className="centres">
                <img className="img-fluid" src={centre4} alt="centre1" />
                {/* <div className="centre-name">Saraswathy vidyanikethan</div> */}
                <div className="centre-location">Kodakara</div>
              </div>
            </Col>
            </Row>
            <Row className="branch-secondrow">
            <Col lg={4} md={6}>
              <div className="centres">
                <img className="img-fluid" src={centre5} alt="centre1" />
                {/* <div className="centre-name">SN Vidyabavan</div> */}
                <div className="centre-location">Chentrappinni</div>
              </div>
            </Col>

            <Col lg={4} md={6}>
              <div className="centres">
                <img className="img-fluid" src={centre6} alt="centre1" />
                {/* <div className="centre-name">St Anne's school</div> */}
                <div className="centre-location">Anathadam</div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}

export default Branches;
