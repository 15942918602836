import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "../header/header";
import GalleryNavBar from "../galleryviewpage/galleryNavBar";
import Footer from "../footer/footer";
import CopyRight from "../copyright/copyright";
import "./galleryviewpage.css";
import axios from "../network/api.js";
import { baseUrl, baseMediaUrl } from "../network/constants/Constants";
import Modal from "react-bootstrap/Modal";

function GalleryPage(props) {
  const [loading, setLoading] = useState(false);
  const [galleryView, setGalleryView] = useState([]);
  const [gallary, setGallary] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [modalShow, setModalShow] = React.useState(false);

  const [selectedIndex, setSelectedIndex] = useState(0);

  const openImageFullscreen = (index) => {
    setSelectedIndex(index);
    handleShow(true);
  };

  useEffect(() => {
    fetchGallery();
  }, []);

  const fetchGallery = () => {
    setLoading(true);

    axios
      .get(baseUrl + "webapp/gallery/")
      .then((response) => {
        console.log(response.data);
        setLoading(false);

        setGalleryView(response.data);
        console.log("gallery" + response.data);
      })
      .catch((error) => {
        // console.log(error + "Arjun");

        setLoading(false);
      });
  };

  return (
    <section id="gallerypage">
      <div>
        <Header />
        <GalleryNavBar />
        <Container>
          <div className="gallery-head">Gallery</div>
          <div className="image">
            <Row>
              {galleryView &&
                galleryView
                  .sort((a, b) => (a.id > b.id ? -1 : 1))
                  .map((galleryObj, index) => {
                    return (
                      <Col lg={4} className="gallery-col">
                        <div
                          className="img-card"
                          onClick={() => openImageFullscreen(index)}
                        >
                          <img
                            className="gallery-img img-fluid"
                            src={galleryObj.gallerybanner}
                            alt="Gallery"
                          />
                        </div>
                      </Col>
                    );
                  })}
            </Row>
          </div>
          {/* {galleryView && (
            <Modal
              show={show}
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton onClick={handleClose}></Modal.Header>
              <Modal.Body>
                {console.log(
                  "gallarybanner: " + gallary[0].gallerybanner,
                  selectedIndex
                )}
                <Col lg={4} className="gallery-col">
                  <div className="img-card">
                    {" "}
                    <img
                      src={gallary[selectedIndex].gallerybanner}
                      className="modalimage img-fluid"
                    />
                  </div>
                </Col>
              </Modal.Body>
            </Modal>
          )} */}
        </Container>
        <Footer />
        <CopyRight />
      </div>
    </section>
  );
}

export default GalleryPage;
