import React from "react";
import Container from "react-bootstrap/esm/Container";
import Academichead from "../assets/department/officer1.jpeg";
import Departmenthead1 from "../assets/department/officer3.jpeg";
import Departmenthead2 from "../assets/department/officer6.jpeg";
import Departmenthead3 from "../assets/department/officer4.jpeg";
import Departmenthead4 from "../assets/department/officer5.jpeg";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import "./department.css";

function DepartmentHeads() {
  return (
    <section id="departments">
      <div>
        <Container>
          {/* <div className="department-heading">Department Heads</div> */}
          {/* <img className="academic-head" src={Academichead} alt="Academic Head" />
      <div className="officer-name">Nithin Rajan</div>
      <div className="designation">Academic Head (Physics)</div> */}
          <div className="department-head">
            <Row className="dept-row">
              <Col sm={12} lg={4} md={6}>
                <img
                  className="head1"
                  src={Departmenthead1}
                  alt="Department Head"
                />

                <div className="department-head-name">
                  Dr. Ananthapadmanabhan
                </div>
                <div className="department-designation">
                  Centre Development Head
                </div>
              </Col>

              {/* <Col sm={12} lg={3} md={6}>
            <img
              className="head1"
              src={Departmenthead3}
              alt="Department Head"
            />
            <div className="department-head-name">Dr. Gireesh</div>
            <div className="department-designation">Biology Department Head</div>
          </Col> */}
              <Col sm={12} lg={4} md={6}>
                <img
                  className="head1"
                  src={Departmenthead4}
                  alt="Department Head"
                />
                <div className="department-head-name">Dr. Muhammad Shafeeq</div>
                <div className="department-designation">
                  Student Wellness Head and Psychologist
                </div>
              </Col>
              <Col sm={12} lg={4} md={6}>
                <img
                  className="academic-head"
                  src={Academichead}
                  alt="Academic Head"
                />
                <div className="department-head-name">Nithin Rajan</div>
                <div className="department-designation">
                  Academic Head (Physics)
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </section>
  );
}

export default DepartmentHeads;
