import React from "react";
import Container from "react-bootstrap/Container";
import phone from "../assets/header/header_phone.svg";
import watsapp from "../assets/header/watsapp.svg";
import instagram from "../assets/header/instagram.svg";
import facebook from "../assets/header/facebook.svg";
import youtube from "../assets/header/youtube.svg";
import "./header.css";

export default function Header() {
  return (
    <section id="header">
      <div>
        <div className="socialmedia-header">
          <Container className="d-flex">
            <div className="phone">
              <a href="tel:9037187800" class="float1" target="_blank">
                <img src={phone} className="pe-2" alt="ic_phone" />
                9037187800
              </a>
              {/* <a href="tel:9745456460" class="float1" target="_blank" > 
      <img src={phone} className="pe-2" alt="ic_phone"/>
      9745456460
      </a> */}
            </div>
            <div className="socialmedia">
              <a
                href="https://api.whatsapp.com/send?phone=9745456460 &amp;text=Hello!"
                class="float"
                target="_blank"
              >
                <img src={watsapp} className="pe-3" alt="watsapp" />
              </a>
              <a
                href="https://www.instagram.com/professors_academy_/?hl=en"
                target="_blank"
              >
                <img src={instagram} className="pe-3" alt="instagram" />
              </a>
              <a
                href="https://www.facebook.com/ProfessorsAcademyKerala"
                target="_blank"
              >
                <img src={facebook} className="pe-3" alt="facebook" />
              </a>
              <a
                href="https://youtube.com/@professorsacademy9022"
                target="_blank"
              >
                <img src={youtube} className="pe-3" alt="youtube" />
              </a>
            </div>
          </Container>
        </div>
      </div>
    </section>
  );
}
