import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import Carousel from "react-bootstrap/Carousel";
import Button from "react-bootstrap/Button";
import "../banner/banner.css";
import Header from "../header/header";
import NotificationNavBar from "../notificationdetails/notificationNavbar";
import Footer from "../footer/footer";
import CopyRight from "../copyright/copyright"; 
import "./notificationdetails.css";
import axios from "../network/api.js";
import { baseUrl, baseMediaUrl } from "../network/constants/Constants";
import {Link} from 'react-router-dom'
// import "./notificationNavbar.css";

function NotificationDetails() {
  const [loading, setLoading] = useState(false);
  const [notificationDetails, setNotificationDetails] = useState([]);

  useEffect(() => {
    fetchDetails();
  }, []);

  const fetchDetails = () => {
    setLoading(true);

    axios
      .get(baseUrl + "webapp/notification")
      .then((response) => {
        setLoading(false);

        setNotificationDetails(response.data);
        console.log("details" + response.data);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <section id="notificationdetails">
       <div>
      <Header />
      <NotificationNavBar />
      {/* <HeaderHome/> */}
      <div className="Notifications_div">
        <Container>
          <div className="heading">Notifications</div>
          <div className="Carousel">
            <div className="border-left"></div>
            <div className="border-right"></div>
            <Carousel variant="dark">
            {notificationDetails &&
                    notificationDetails.map((description, index) => {
                      return (
              <Carousel.Item>
                <div className="carousel-details">
                 
                        <div className="sub-heading">
                          {description.title}
                          {/* ADMISSION TO MBBS/BDS COURSES IN THE STATE OF KERALA
                          CANDIDATES CAN RECTIFY DEFECTS IN SUBMITTED DOCUMENTS
                          FOR NRI QUOTA - 2022 */}
                       
                    
                        <div className="description mt-4">
                          {description.description}
{/* 
                          <p>
                            Candidates, who have submitted NRI documents for
                            admission to NRI quota seats in MBBS/BDS courses
                            available in self financing Medical & Dental
                            Colleges in the State of Kerala for the year 2022,
                            can rectify the defects in submitted NRI documents.
                            Candidates who have already submitted NRI documents
                            along with online application within the stipulated
                            time can rectify the defects upto 13.10.2022, 4.00
                            PM. To rectify the defects, candidates should login
                            to the ‘Keam-2022 Candidate Portal’ by submitting
                            their application number and password. Then by
                            clicking on the link ‘Memo details’ candidates can
                            upload NRI documents for rectify the defects.
                          </p> */}
                         
                          <Button className="apply" type="submit">
                          <a href={description.link}>View More</a>                    
                          </Button>
                          </div>
                        </div>
                     
                </div>
              </Carousel.Item>
              );
                    })}
              {/* <Carousel.Item>
                <div className="carousel-details">
                  <div className="sub-heading">
                    ADMISSION TO MBBS/BDS COURSES IN THE STATE OF KERALA
                    CANDIDATES CAN RECTIFY DEFECTS IN SUBMITTED DOCUMENTS FOR
                    NRI QUOTA - 2022
                  </div>
                  <div className="description mt-4">
                    <p>
                      Candidates, who have submitted NRI documents for admission
                      to NRI quota seats in MBBS/BDS courses available in self
                      financing Medical & Dental Colleges in the State of Kerala
                      for the year 2022, can rectify the defects in submitted
                      NRI documents. Candidates who have already submitted NRI
                      documents along with online application within the
                      stipulated time can rectify the defects upto 13.10.2022,
                      4.00 PM. To rectify the defects, candidates should login
                      to the ‘Keam-2022 Candidate Portal’ by submitting their
                      application number and password. Then by clicking on the
                      link ‘Memo details’ candidates can upload NRI documents
                      for rectify the defects.
                    </p>
                    <br />

                    <p>
                      For those candidates with memo for defects in the NRI
                      documents submitted, the last date for submission of
                      document will be 13.10.2022, 4 PM. Those candidates who
                      have uploaded expired or expiring visa along with online
                      application can submit the sworn affidavit from the
                      sponsor in the prescribed proforma available in the
                      website of Commissioner For Entrance Examinations as per
                      the direction from Admission Supervisory Committee, within
                      the above date. Those candidates, who had uploaded visa
                      with validity expired/expiring and have been given
                      relaxation as per the direction of Admission Supervisory
                      Committee, to upload sworn affidavit, will have to upload
                      the valid visa/online acknowledgement copy received at the
                      time of submitting the application for visa renewal on or
                      before the last date fixed by the commissioner for
                      Entrance Examinations for option confirmation to the
                      second phase allotment to MBBS/BDS courses. Such
                      candidates will be provisionally allowed in File
                      No.CEE/717/2022-TA4 the NRI category list to be published
                      by the Commissioner for Entrance Examinations and their
                      allotment in NRI quota seats will also be provisional. If
                      such candidates will not submit the original valid visa on
                      or before the last date fixed by the Commissioner for
                      Entrance Examinations for option confirmation to the
                      Second Phase allotment to MBBS/BDS courses, the
                      provisional allotment in NRI quota seats and the NRI
                      status will be cancelled. Such candidates will not be
                      considered for further allotments in NRI quota seats in
                      MBBS/BDS courses.
                    </p>
                    <br />

                    <p>
                      As the rank list, category list and allotment list have to
                      be published within the stipulated time, the time for
                      rectification for NRI documents will not be extended
                      further. The documents received through mail/by post/by
                      hand will not be considered for allowing NRI claim, at any
                      instance.
                    </p>
                  </div>
                  <Button className="apply" type="submit">
                    Apply Now
                  </Button>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="carousel-details">
                  <div className="sub-heading">
                    ADMISSION TO MBBS/BDS COURSES IN THE STATE OF KERALA
                    CANDIDATES CAN RECTIFY DEFECTS IN SUBMITTED DOCUMENTS FOR
                    NRI QUOTA - 2022
                  </div>
                  <div className="description mt-4">
                    <p>
                      Candidates, who have submitted NRI documents for admission
                      to NRI quota seats in MBBS/BDS courses available in self
                      financing Medical & Dental Colleges in the State of Kerala
                      for the year 2022, can rectify the defects in submitted
                      NRI documents. Candidates who have already submitted NRI
                      documents along with online application within the
                      stipulated time can rectify the defects upto 13.10.2022,
                      4.00 PM. To rectify the defects, candidates should login
                      to the ‘Keam-2022 Candidate Portal’ by submitting their
                      application number and password. Then by clicking on the
                      link ‘Memo details’ candidates can upload NRI documents
                      for rectify the defects.
                    </p>
                    <br />

                    <p>
                      For those candidates with memo for defects in the NRI
                      documents submitted, the last date for submission of
                      document will be 13.10.2022, 4 PM. Those candidates who
                      have uploaded expired or expiring visa along with online
                      application can submit the sworn affidavit from the
                      sponsor in the prescribed proforma available in the
                      website of Commissioner For Entrance Examinations as per
                      the direction from Admission Supervisory Committee, within
                      the above date. Those candidates, who had uploaded visa
                      with validity expired/expiring and have been given
                      relaxation as per the direction of Admission Supervisory
                      Committee, to upload sworn affidavit, will have to upload
                      the valid visa/online acknowledgement copy received at the
                      time of submitting the application for visa renewal on or
                      before the last date fixed by the commissioner for
                      Entrance Examinations for option confirmation to the
                      second phase allotment to MBBS/BDS courses. Such
                      candidates will be provisionally allowed in File
                      No.CEE/717/2022-TA4 the NRI category list to be published
                      by the Commissioner for Entrance Examinations and their
                      allotment in NRI quota seats will also be provisional. If
                      such candidates will not submit the original valid visa on
                      or before the last date fixed by the Commissioner for
                      Entrance Examinations for option confirmation to the
                      Second Phase allotment to MBBS/BDS courses, the
                      provisional allotment in NRI quota seats and the NRI
                      status will be cancelled. Such candidates will not be
                      considered for further allotments in NRI quota seats in
                      MBBS/BDS courses.
                    </p>
                    <br />

                    <p>
                      As the rank list, category list and allotment list have to
                      be published within the stipulated time, the time for
                      rectification for NRI documents will not be extended
                      further. The documents received through mail/by post/by
                      hand will not be considered for allowing NRI claim, at any
                      instance.
                    </p>
                  </div>
                  <Button className="apply" type="submit">
                    Apply Now
                  </Button>
                </div>
              </Carousel.Item>  */}
            </Carousel>
          </div>
        </Container>
      </div>
      <Footer />
      <CopyRight />
    </div>
    </section>
   
  );
}

export default NotificationDetails;
