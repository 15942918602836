import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import c1 from "../assets/course image/Frame 82.png";
import "./courses.css";
import axios from "../network/api";
import { baseUrl, config } from "../network/constants/Constants";

function CourseDetails() {
  const [loading, setLoading] = useState(false);
  const [course, setCourse] = useState([]);

  useEffect(() => {
    fetchCourse();
  }, []);

  const fetchCourse = () => {
    setLoading(true);

    axios
      .get(baseUrl + "webapp/courses/")
      .then((response) => {
        console.log(response.data);
        setLoading(false);

        setCourse(response.data);
        console.log("course" + response.data);
      })

      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <section id="course">
      <div>
        <Container>
          <div className="main-heading">Courses</div>
          <div className="course_details">
            <Row>
              {course &&
                course
                  .sort((a, b) => (a.id > b.id ? 1 : -1))
                  .map((courseId, index) => {
                    return (
                      <Col lg={6} className="course-col">
                        <div className="service-card">
                          <img src={courseId.image} alt="courses" />
                          <div className="course-batch">
                            <div className="course-head">
                              {courseId.course_name}
                            </div>
                            <Container>
                              <div className="course-data">
                                {/* <ul>
                              <li> */}
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: courseId.course_description,
                                  }}
                                />
                                {/* </li>
                            </ul> */}
                              </div>
                            </Container>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
            </Row>
          </div>
        </Container>
      </div>
    </section>
  );
}

export default CourseDetails;
